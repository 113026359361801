import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Rectangular Calculator",
    link: "/rectangular-volume-and-area-calculator/",
  },
]
const seeAlsoArray = [
  "/capsule-volume-and-area-calculator",
  "/cone-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
  "/percentage-calculator",
  "/ellipsoid-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/square-pyramid-volume-and-area-calculator",
]

function RectangularCalculatorVolumeArea(props) {
  let [result, setresult] = useState("")
  let unitSelector = useRef("")
  let width = useRef("")
  let Length = useRef("")
  let Distance = useRef("")

  function calcVolume(e) {
    let widthValue = width.current.value
    let LengthValue = Length.current.value
    let DistanceValue = Distance.current.value
    let unit = unitSelector.current.value

    if (widthValue === "" || isNaN(widthValue)) {
      setresult(<strong> Please Enter width Value </strong>)
    } else if (LengthValue === "" || isNaN(LengthValue)) {
      setresult(<strong> Please Enter Length Value</strong>)
    } else if (DistanceValue === "" || isNaN(DistanceValue)) {
      setresult(<strong> Please Enter Distance Value</strong>)
    } else {
      widthValue = Number(widthValue)
      DistanceValue = Number(DistanceValue)
      LengthValue = Number(LengthValue)

      let volume = widthValue * DistanceValue * LengthValue

      setresult(
        <span>
          Volume is : {volume.toFixed(2) + unit}
          <sup>3</sup>
        </span>
      )
    }
  }
  function calcArea(e) {
    let widthValue = width.current.value
    let LengthValue = Length.current.value
    let DistanceValue = Distance.current.value
    let unit = unitSelector.current.value

    if (widthValue === "" || isNaN(widthValue)) {
      setresult(<strong> Please Enter width Value </strong>)
    } else if (LengthValue === "" || isNaN(LengthValue)) {
      setresult(<strong> Please Enter Length Value</strong>)
    } else if (DistanceValue === "" || isNaN(DistanceValue)) {
      setresult(<strong>Please Enter Distance Value</strong>)
    } else {
      widthValue = Number(widthValue)
      DistanceValue = Number(DistanceValue)
      LengthValue = Number(LengthValue)

      let Area =
        2 *
        (widthValue * DistanceValue +
          widthValue * LengthValue +
          DistanceValue * LengthValue)
      let DL = DistanceValue * LengthValue
      let DW = DistanceValue * widthValue
      setresult(
        <span>
          Total Surface area : {Area.toFixed(2) + unit}
          <sup>2</sup> <br />
          Area of (D) and (L) side : {DL.toFixed(2) + unit}
          <sup>2</sup> <br />
          Area of (D) and (W) side : {DW.toFixed(2) + unit}
          <sup>2</sup>
        </span>
      )
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Rectangular Volume and Area Calculator"
        description="Rectangular Volume and Area Calculator, Simple calculate rectangular Volume and Area. The volume of a Rectangle or Container is generally understood to be the capacity of the Rectangle or Container."
        keywords={[
          "Rectangular Calculator , Rectangular Calculator Volume, Rectangular Calculator Area, rectangular volume, rectangle area, rectangle volume, rectangle calculate, fast Rectangular Calculator, sinple Rectangular Calculator.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Rectangular Volume and Area Calculator</Title>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Width (W)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={width}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>Length (L)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={Length}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>Distance (D)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={Distance}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Select Unit</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Select>
                  <select
                    aria-label="unitSelector"
                    className="staticSelect"
                    ref={unitSelector}
                  >
                    <option value="m">m</option>
                    <option value="cm">cm</option>
                    <option value="mm">mm</option>
                    <option value="km">km</option>
                    <option value="dm">dm</option>
                  </select>
                </Select>
              </Control>
            </Field>
            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calcVolume}
            >
              Calculate Volume
            </Button>
            <Button
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={calcArea}
            >
              Calculate area
            </Button>

            <p className="h5 mt-3">{result}</p>
          </Column>
          <Column>
            <FlexDiv maxWidth="250px" margin="auto">
              <SmallImg filename="rectangle.png" alt="Rectangular calculator" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <h3>How To Calculate Rectangle Volume?</h3>
        <p>
          <strong>
            Volume = width * lenght * distance , <br />
            ex : width = 15m, lenght = 20, distance = 25
            <br />
            Volume = 15 * 20 * 25 = 7500m<sup>3</sup>{" "}
          </strong>
        </p>
        <h3>Rectangle Volume Info</h3>
        <p>
          The volume of a Rectangle is generally known to be the capacity of the
          Rectangle. Volume is usually quantified numerically using the SI
          derived unit, the cubic meter.
        </p>

        <h3>How To Calculate Rectangle area?</h3>
        <p>
          <strong>
            Total Area = 2 * (width * distance + width * lenght + distance *
            lenght) .
            <br />
            ex : width = 10, lenght = 30, distance = 20.
            <br />
            Area = 2* ( 10 * 20 + 20 * 30 + 20 * 30 ) = 2200 m<sup>2</sup>.
          </strong>
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default RectangularCalculatorVolumeArea
